import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3499616894/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "core-forms"
    }}>{`Core Forms`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`globals -> () => Promise|Promise`}</inlineCode>{` = Code to load prior to loading the form.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`submission -> string`}</inlineCode>{` =  Id of the submission to load.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`form -> string`}</inlineCode>{` = Slug of the form to load if submission id is not provided.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`kapp -> string`}</inlineCode>{` = Slug of the kapp which contains the form defined by formSlug.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`datastore -> boolean`}</inlineCode>{` =  Boolean determining if the form defined by formSlug is a datastore form. */`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`values -> { [valueName: string]: string }`}</inlineCode>{` = Map of field values to pass to the form.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`review -> boolean`}</inlineCode>{` = Boolean determining if the form should be opened in review mode.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onUnauthorized -> () => null`}</inlineCode>{` = Callback function that will execute when the form returns a 401 error.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onForbidden -> () => null`}</inlineCode>{` = Callback function that will execute when the form returns a 403 error.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onNotFound -> () => null`}</inlineCode>{` = Callback function that will execute when the form returns a 404 error.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onError -> () => null`}</inlineCode>{` = Callback function that will execute when the form returns any error.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onCreated -> () => null`}</inlineCode>{` = Callback function that will execute when a submission is created.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onUpdated -> () => null`}</inlineCode>{` = Callback function that will execute when a submission is updated.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onCompleted -> () => null`}</inlineCode>{` = Callback function that will execute when a submission is completed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`originId -> string`}</inlineCode>{` = The ID of the originating submission.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`parentId -> string`}</inlineCode>{` = The ID of the parent submission.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`components -> object`}</inlineCode>{` = Component overrides map.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`pendingComponent -> ReactElement()`}</inlineCode>{` = Component to display when the form is loading. Deprecated: Use components.Pending instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`unauthorizedComponent -> ReactElement()`}</inlineCode>{` = Component to display when the form returns a 401 error. Deprecated: Use components.Unauthorized instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`forbiddenComponent -> ReactElement()`}</inlineCode>{` = Component to display when the form returns a 403 error. Deprecated: Use components.Forbidden instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`notFoundComponent -> ReactElement()`}</inlineCode>{` = Component to display when the form returns a 404 error. Deprecated: Use components.NotFound instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`unexpectedErrorComponent -> ReactElement()`}</inlineCode>{` = Component to display when the form returns any other error. Deprecated: Use components.Unexpected instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`layoutComponent -> ReactElement()`}</inlineCode>{` = Component used to display the entire content of the CoreForm. Deprecated: Use components.Layout instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`reviewPaginationControlComponent -> ReactElement()`}</inlineCode>{` = Component used to display pagination when the form is in review mode and has multiple displayable pages. Deprecated: Use components.ReviewPaginationControl instead.`}</li>
    </ul>
    <h2 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { CoreForm } from '@kineticdata/react'

const FeedbackForm = ({ kappSlug }) => (
  <CoreForm
    kapp={kappSlug}
    form="feedback-form"
  />
);
`}</code></pre>
    <h2 {...{
      "id": "advanced-example"
    }}>{`Advanced Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { CoreForm } from '@kineticdata/react'

const onCompleted = () => alert('Thank you for submitting feedback!');

const FormLayout = ({ form, content }) => (
  <div className="row">
    <div className="col-12">
      <div className="form-header">{form.name}</div>
      <div className="form-container">
        {content}
      </div>
    </div>
  </div>
);

const Pending = () => (
  <div className="loading">Loading form...</div>
);

const FeedbackForm = ({ kappSlug }) => (
  <CoreForm
    kapp={kappSlug}
    form="feedback-form"
    onCompleted={onCompleted}
    components={{
      Pending,
      Layout: FormLayout
    }}
  />
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      